/* eslint-disable */
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import produce from "immer";
import { LoadedEditorResources } from "@/features/editor/use-editor-loader";
import { ExperienceStateHook, useExperienceState } from "@/features/editor/context/use-experience-state";
import { TranspilerHook, useTranspiler } from "@/features/editor/context/use-transpiler";
import {
  BaseMessage,
  CheckoutNewComponent,
  CopyElementMessage,
  DevicePreviewHook,
  ElementMessage,
  ExtensionType,
  ExternalEditMessage,
  LightweightMountPoint,
  LoadedMessage,
  MoveElementMessage,
  NavigateToMessage,
  NewElementMessage,
  OpenLinkMessage,
  PageLoadErrorMessage,
  useDevicePreview
} from "@/features/editor/context/use-device-preview";
import { InspectorNavigationHook, useInspectorNavigation } from "@/features/editor/context/use-inspector-navigation";
import {
  EditorChangeKind,
  EditorDeclarativeBlock,
  HtmlTransformKind,
  PlacementKind
} from "@/webapi/use-experience-api";
import {
  MINI_CART_TAG,
  useDeviceNavigation,
  UseDeviceNavigationHook
} from "@/features/editor/context/use-device-navigation";
import { UseExperienceControls, useExperienceControls } from "@/features/editor/context/use-experience-controls";
import { CustomWidgetStep } from "@/features/editor/widgets/custom-widget/shared/context";
import { shouldSync } from "@/utils/experience-utils";
import { DeviceType } from "@/utils/definitions";
import { AccountContext, useFeatureBit } from "@/features/account-context";
import { useWidgetCatalogApi } from "@/webapi/use-widget-catalog-api";
import { EDITOR_EXPERIENCE_CACHE, useCachedAutoSave } from "@/components/hooks/use-cached-auto-save";
import { useDetachedState } from "@/components/hooks/use-detached-state";
import { mutateFn, useComplexState } from "@/utils/use-complex-state";
import { ORIGINAL_EXP_ID, ORIGINAL_EXP_NAME } from "@/features/editor/shared/external_experience_widget";
import { WidgetChange } from "@/pkg/sdk";
import { Env, routes, SHOPIFY_POST_PURCHASE_PROD, SHOPIFY_POST_PURCHASE_STG } from "@/webapi/routes";
import { getDeviceBasedEditorId } from "@/webapi/common";
import { FeatureBit } from "@/webapi/use-auth-api";
import { CatalogCheckoutApp } from "@/webapi/checkout-widget-props";
import { openLinkInNewTab } from "@/utils/browser";
import { useCheckoutRecommend } from "@/features/editor/widgets/checkout-extensibility/loading/use-checkout-recommend";
import { Audience } from "@/features/dashboard/audiences/models";
import { useWatchLoadingLoop } from "@/features/editor/context/use-watch-loading-loop";
import { maybe } from "@/features/details/utils";


export const EditorContext = React.createContext({} as EditorContextProps);

export function newEditorContext(
  resourcesOrig: LoadedEditorResources,
): EditorContextProps {
  const {
    account: {
      store: { alias, domain, mainAlias },
    },
  } = useContext(AccountContext);
  const [resources, setResources] =
    useComplexState<LoadedEditorResources>(resourcesOrig);
  const [integrationType,setIntegrationType] = useState<'static'|'spa'>('static');
  const [checkoutApps, setCheckoutApps] = useState([]);
  const [checkoutMountPoints, setCheckoutMountPoints] = useState<LightweightMountPoint[]>([]);
  const [checkoutSnapshotTimestamp, setCheckoutSnapshotTimestamp] = useState(0);
  const [currentExtension, setCurrentExtension] = useState<ExtensionType>(``);
  const [, setIsInErrorState, isInErrorStateRef] = useDetachedState(false);
  const loadedApps = useRef<Array<string>>([]);
  const [resetWidget, setResetWidget] = useState(false);

  const inspectorRef = useRef<HTMLElement>(null);
  const deviceRef = useRef<HTMLElement>(null);
  const { previewLoading, setPreviewLoading, previewLoadingRef } = usePreviewLoading();

  const [lastPickedElement, setLastPickedElement] = useDetachedState(
    {} as NewElementMessage,
  );

  useEffect(() => {
    const interval = setInterval(() => {
      try {
        const iframe = window.document.querySelector("#navigator-iframe")as any;
        if (iframe?.contentWindow){
          const vslyIntegrationType = iframe.contentWindow.vslyIntegrationType;
          if (vslyIntegrationType){
            setIntegrationType(vslyIntegrationType);
            clearInterval(interval);
          }
        }} catch (e) {}
    }, 100);
    return () => clearInterval(interval)
  }, [])

  const [newAudiences, setNewAudiences] = useState<Array<Audience>>([]);
  const isSpaIntegration = useFeatureBit(FeatureBit.SPA_INTEGRATION)
  const isThemesEnabled =
    useFeatureBit(FeatureBit.SHOPIFY_THEMES) && !isSpaIntegration;
  const experienceState = useExperienceState(
    expWithDefaultTheme(resources, isThemesEnabled),
    resources.appsCatalog,
  );
  const transpiler = useTranspiler();
  const inspectorNav = useInspectorNavigation(
    resources.appsCatalog,
    resources.experience.id,
  );

  useCachedAutoSave(
    experienceState.currentExperience,
    experienceState.currentExperience.id,
    EDITOR_EXPERIENCE_CACHE,
  );

  useCheckoutRecommend(currentExtension, experienceState)

  const [lastChanges, setLastChanges] = useState(
    JSON.stringify(experienceState.getCurrentVariant()?.changes),
  );
  const { getChange } = useWidgetCatalogApi(false);

  const onAnchorMessageReceived = async (msg: ElementMessage) => {
    if (msg.widgetId && msg.widgetId !== ``) {
      const change = experienceState.findEditorChange(msg.loomiId);
      await inspectorNav.gotoCustomWidget(change, CustomWidgetStep.CUSTOMIZE);
    } else {
      const change = transpiler.asCompoundChange(
        EditorChangeKind.EDIT_COMPOUND,
        HtmlTransformKind.REPLACE,
        msg.selector,
        msg.selector,
        msg.html,
        msg.css,
        msg.js,
        compoundChangeId(
          msg.selector,
          experienceState?.currentExperience?.quickPreviewDevice,
        ),
      );
      inspectorNav.gotoCompoundEditor(change, true);
    }
  };

  function handleNavigateTo(msg: BaseMessage) {
    const casted = msg as NavigateToMessage;
    if (casted.url.startsWith(`/`)) {
      casted.url = `${domain}${casted.url}`;
    }
    deviceNavigation.navigateTo(casted.url);
  }

  async function handleVisualEdit(msg: BaseMessage) {
    const casted = msg as ElementMessage;
    if (casted.widgetId) {
      const change = experienceState.findEditorChange(casted.loomiId);
      await inspectorNav.gotoCustomWidget(change, CustomWidgetStep.CUSTOMIZE);
    } else if (casted.loomiId && currentExtension === `shopify-checkout-extensibility`) {
      if (currentExtension === `shopify-checkout-extensibility`) {
        const change = experienceState.findEditorChange(casted.loomiId);
        inspectorNav.gotoCheckoutWidgetEdit(change)
      }
    } else {
      const editorId = getDeviceBasedEditorId(
        casted.selector,
        devicePreview.editorState.device,
        experienceState.currentExperience.experienceCreationVersion,
      )
      const change = experienceState.findEditorChange(editorId);
      if (change?.block?.kind === `compound`) {
        inspectorNav.gotoLegacyVisualEditor(casted, change);
      } else {
        inspectorNav.gotoVisualEditor(
          casted.selector,
          casted.visualProps,
          change,
        );
      }
    }
  }

  function handleCodeEdit(msg: BaseMessage) {
    const casted = msg as ElementMessage;
    const change = transpiler.asCompoundChange(
      EditorChangeKind.EDIT_COMPOUND,
      HtmlTransformKind.REPLACE,
      casted.selector,
      casted.selector,
      casted.html,
      casted.css,
      casted.js,
      compoundChangeId(
        casted.selector,
        experienceState?.currentExperience?.quickPreviewDevice,
      ),
    );
    inspectorNav.gotoCompoundEditor(change, casted.isHtmlEditable);
  }

  function handleRemoveElement(msg: BaseMessage) {
    const casted = msg as ElementMessage;
    const existing = experienceState.findEditorChange(casted.loomiId);
    if (existing) {
      experienceState.removeEditorChange(existing);
    } else {
      if (currentExtension === `shopify-checkout-extensibility`) {
        const delChange = transpiler.asHideCheckoutWidgetChange(casted.loomiId)
        experienceState.upsertEditorChange(delChange);
      } else {
        const delChange = transpiler.asHideElementBlock(
          casted.selector,
          devicePreview.editorState.device,
        );
        experienceState.upsertEditorChange(delChange);
      }
    }
  }

  async function duplicateWidget(msg: ExternalEditMessage) {
    const change = await buildDuplicateWidgetChange(msg);
    inspectorNav.gotoCustomWidget(change, CustomWidgetStep.CUSTOMIZE).then();
  }

  async function buildDuplicateWidgetChange(msg: ExternalEditMessage) {
    const { change } = await getChange(msg?.experienceId, msg?.loomiId);
    change.editorId = `${msg?.loomiId}_dup`;
    const value = change.block.value as WidgetChange;
    value.env[`sectionId`] = `#${change.editorId}`;
    value.env[ORIGINAL_EXP_NAME] = msg.experienceName;
    value.env[ORIGINAL_EXP_ID] = msg.experienceId;
    change.editorSelector = `#${change.editorId}`;
    value.htmlKind = `replace`;
    change.block.kind = `widget`;
    change.block.selector = `#${msg?.loomiId}`;
    return change;
  }

  async function copyElement(msg: CopyElementMessage) {
    let change: EditorDeclarativeBlock;
    if (!!msg.widgetId && !!msg.experienceId) {
      change = await buildDuplicateWidgetChange({
        action: `copyElement`,
        experienceId: msg.experienceId,
        experienceName: msg.experienceName,
        widgetId: msg.widgetId,
        loomiId: msg.loomiId,
      });
    } else if (!!msg.widgetId && !msg.experienceId) {
      change = experienceState.findEditorChange(msg.loomiId);
    } else {
      change = transpiler.asCompoundChange(
        EditorChangeKind.NEW_COMPONENT,
        HtmlTransformKind.APPEND_AFTER,
        msg.selector,
        msg.selector,
        msg.html,
      );
    }

    const newChange = produce(change, (draft) => {
      const id = `_loomi_addon_${new Date().getTime()}`;
      draft.editorId = id;
      draft.editorSelector = `#${id}`;
      draft.block.value[`htmlKind`] = `appendAfter`;
      draft.block.selector = `#vsly-invalid-selector`;
      if (draft.block.value?.[`env`]) {
        draft.block.value[`env`][`sectionId`] = draft.editorSelector;
      }
    });

    inspectorNav.gotoChangeSelector(newChange, true);
  }

  async function moveElement(msg: MoveElementMessage) {
    if (
      !!msg.widgetId ||
      (!!msg.loomiId && !!experienceState.findEditorChange(msg.loomiId))
    ) {
      const change = experienceState.findEditorChange(msg.loomiId);
      inspectorNav.gotoChangeSelector(change, false);
    } else if (msg.origMoveSelector) {
      const editorId = getDeviceBasedEditorId(
        `move-${msg.origMoveSelector}`,
        devicePreview.editorState.device,
        experienceState.currentExperience.experienceCreationVersion,
      );
      const change = experienceState.findEditorChange(editorId);
      inspectorNav.gotoMoveElement(change, false);
    } else {
      const change = transpiler.asMoveChange(
        getDeviceBasedEditorId(
          `move-${msg.selector}`,
          devicePreview.editorState.device,
          experienceState.currentExperience.experienceCreationVersion,
        ),
        msg.selector,
        msg.selector,
        `appendBefore`,
      );
      inspectorNav.gotoMoveElement(change, true);
    }
  }


  const handleLoadedMessage = (msg: LoadedMessage) => {
    if (msg.fonts?.length > 0) {
      devicePreview.setInstalledFonts(msg.fonts, msg.mostPopularFont);
    }
    setPreviewLoading(false);

    if (msg.extensionName === `shopify-post-purchase`) {
      setCurrentExtension(`shopify-post-purchase`)
      window.dispatchEvent(new CustomEvent(`post-purchase-reload`));
      inspectorNav.gotoPostPurchaseEditor();
    } else if (msg.extensionName === `shopify-checkout-extensibility`) {
      setCurrentExtension(`shopify-checkout-extensibility`)
      if (msg.catalog) {
        msg?.catalog.forEach(app => {
          app?.checkoutWidgets?.forEach(widget => {
            if (!!widget?.initialState?.["loadingEnv"]?.[`recommendationOptions`]) {
              widget.initialState["loadingEnv"][`recommendationOptions`][`storeAlias`] = alias;
            }
          })
        })
        setCheckoutApps(msg.catalog);
      }
      handleCheckoutLoadedMessage(msg);
    } else {
      if (currentExtension === `shopify-checkout-extensibility`) {
        setTimeout(() => {inspectorNav.gotoChangelog()}, 500)
      } else {
        const redirectChange = experienceState.getPageRedirectChange()
        if (redirectChange) {
          setTimeout(() => {inspectorNav.gotoPageRedirectWidget(redirectChange)}, 500)
        }
        const themeChange = experienceState.getThemeChangeChange()
        if (themeChange) {
          setTimeout(() => {inspectorNav.gotoThemeTest(themeChange)}, 500)
        }
      }

      setCurrentExtension(``)
    }
  };

  function handleCheckoutLoadedMessage(msg: LoadedMessage) {
    setCheckoutMountPoints(msg?.mountPoints || [])
    setCheckoutSnapshotTimestamp(msg?.snapshotTimestamp || 0)
    const count = msg.mountPointsCount || 0;
    if (count == 0) {
      inspectorNav.gotoCheckoutNoMountPoints()
    } else {
      inspectorNav.gotoChangelog()
    }
  }

  function handleLoadingError(msg: PageLoadErrorMessage) {
    const placementKind =
      experienceState.currentExperience.targeting.placement.kind;
    const redirectionUrl = (
      msg?.redirectionUrl ||
      msg?.originalUrl ||
      ``
    ).replace(domain, ``);

    const isInAllProducts = () =>
      placementKind === PlacementKind.ALL_PRODUCTS &&
      redirectionUrl?.includes(`/products/`);

    const isInAllCollections = () =>
      placementKind === PlacementKind.ALL_CATEGORIES &&
      redirectionUrl.includes(`/collections/`);

    if (
      msg.type === `REDIRECTION` &&
      (isInAllProducts() || isInAllCollections())
    ) {
      let navUrl = redirectionUrl.startsWith(`https://`)
        ? redirectionUrl
        : `${domain}${redirectionUrl}`;
      navUrl = navUrl.replace(`?init-req=true`, ``);
      deviceNavigation.navigateTo(navUrl);
    } else if (msg.type === `ERROR` && isInAllProducts()) {
      const page = deviceNavigation
        .getRelatedUrls()[0]
        .filter((_) => Math.round(Math.random() * 100) % 3 !== 0)?.[0];
      deviceNavigation.navigateTo(page.url);
    } else if (msg.type === `ERROR` && isInAllCollections()) {
      const page = deviceNavigation
        .getRelatedUrls()[0]
        .filter((_) => Math.round(Math.random() * 100) % 3 !== 0)?.[0];
      deviceNavigation.navigateTo(page.url);
    } else {
      setIsInErrorState(true);
    }
  }

  function handleOpenMiniCart() {
    const placementKind = maybe(() => experienceState.currentExperience.targeting.placement.kind);
    const previewUrl = maybe(() => deviceNavigation.previewUrl);
    if (placementKind === PlacementKind.MINI_CART || (typeof previewUrl === `string` && previewUrl.includes(MINI_CART_TAG))) {
      devicePreview.sendJsCode('window.loomi_api?.openDrawer()');
      return;
    }
  }

  function handleCheckoutNewComponent(msg: CheckoutNewComponent) {
    inspectorNav.gotoCheckoutAppListing(msg);
  }

  const onInboundMessage = async (msg: BaseMessage) => {
    switch (msg.action) {
      case `anchor`:
        await onAnchorMessageReceived(msg as ElementMessage);
        break;
      case `create`:
        inspectorNav.gotoAppCatalog(msg as NewElementMessage);
        break;
      case `exit`:
        inspectorNav.gotoChangelog();
        break;
      case `loaded`:
        handleLoadedMessage(msg as LoadedMessage);
        handleOpenMiniCart()
        break;
      case `loading`:
        setPreviewLoading(true);
        devicePreview.sendInitMessage();
        break;
      case `load_error`:
        setPreviewLoading(false);
        handleLoadingError(msg as PageLoadErrorMessage);
        break;
      case `pick`:
        setLastPickedElement(msg as NewElementMessage);
        break;
      case `navigateTo`:
        handleNavigateTo(msg);
        break;
      case `visualEdit`:
        await handleVisualEdit(msg);
        break;
      case `codeEdit`:
        handleCodeEdit(msg);
        break;
      case `removeElement`:
        handleRemoveElement(msg);
        break;
      case `editExternal`:
        duplicateWidget(msg as ExternalEditMessage);
        break;
      case `copyElement`:
        copyElement(msg as CopyElementMessage);
        break;
      case `moveElement`:
        moveElement(msg as MoveElementMessage);
        break;
        case `checkoutNewComponent`:
          handleCheckoutNewComponent(msg as CheckoutNewComponent)
        break;
      case `openLink`:
        openLinkInNewTab((msg as OpenLinkMessage).url, (msg as OpenLinkMessage).tabName);
      default:
        console.warn(
          `vsly-editor`,
          `no handler for message: ${msg.action}`,
          msg,
        );
    }
  };

  const devicePreview = useDevicePreview(
    onInboundMessage,
    experienceState.latestChangeEdit,
    experienceState.setQuickPreviewInfo,
    mainAlias || alias,
    resources.experience.id,
    experienceState?.currentExperience?.targeting?.other,
  );
  useKeepPreviewDevice(experienceState, devicePreview);

  const experienceControls = useExperienceControls(
    experienceState,
    inspectorNav,
    devicePreview?.editorState?.device,
  );

  const deviceNavigation = useDeviceNavigation(
    resources.collections,
    resources.products,
    experienceState.currentExperience,
    experienceState.setQuickPreviewInfo,
    setPreviewLoading,
    loadedApps,
    devicePreview?.editorState?.device
  );

  useWatchLoadingLoop(previewLoadingRef, devicePreview, deviceNavigation, handleLoadedMessage);

  useEffect(() => {
    const currentChanges = JSON.stringify(
      experienceState?.getCurrentVariant()?.changes,
    );
    if (previewLoading === false && lastChanges !== currentChanges) {
      setLastChanges(currentChanges);
      resyncMutations();
    }
  }, [previewLoading, experienceState?.getCurrentVariant()?.changes]);

  useEffect(() => {
    if (!previewLoading) {
      resyncMutations();
    }
  }, [previewLoading, experienceState.currentExperience.quickPreviewPlacement]);

  const getTargetedChanges = () =>
    experienceState
      .getCurrentVariant()
      .changes?.filter((ch) =>
        shouldSync(experienceState.currentExperience, ch),
      );

  const revertTempChange = () => {
    setResetWidget(!resetWidget);
  };

  useEffect(() => {
    if (!previewLoading) {
      setIsInErrorState(false);
    }
  }, [previewLoading]);

  const applyTempChange = useDebouncedCallback(
    (change: EditorDeclarativeBlock) => {
      let mutationsCopy: EditorDeclarativeBlock[];

      if (experienceState.getCurrentVariant()?.changes?.length > 0) {
        const targetedChanges = getTargetedChanges() || [];
        mutationsCopy = JSON.parse(JSON.stringify(targetedChanges));
      } else {
        mutationsCopy = [];
      }

      const tempChange = JSON.parse(
        JSON.stringify(change),
      ) as EditorDeclarativeBlock;

      const idx = mutationsCopy.findIndex(
        (m) => m.editorId === tempChange.editorId,
      );
      if (idx > -1) {
        tempChange.block.order = mutationsCopy[idx].block.order;
        mutationsCopy[idx] = tempChange;
      } else {
        mutationsCopy.push(tempChange);
      }
      devicePreview.syncChanges(transpiler.asDeclarativeBlock(mutationsCopy));
    },
    100,
  );

  const resyncMutations = () => {
    const mutations = transpiler.asDeclarativeBlock(getTargetedChanges());
    devicePreview.syncChanges(mutations);
  };

  const isEditingCheckoutExtensibility = useMemo(() => {
    return currentExtension === `shopify-checkout-extensibility` ||
      deviceNavigation.previewUrl.includes(`shopify_checkout_extensibility`);
  }, [deviceNavigation.previewUrl, currentExtension])



  useEffect(() => {
    if (resourcesOrig.experience.isPostPurchase) {
      const isProd = routes.getEnvEnum() === Env.PRODUCTION;
      deviceNavigation.navigateTo(
        isProd ? SHOPIFY_POST_PURCHASE_PROD : SHOPIFY_POST_PURCHASE_STG,
      );
    }
  }, []);
  return {
    transpiler,
    experienceState,
    inspectorNav,
    devicePreview,
    deviceNavigation,
    experienceControls,
    revertTempChange,
    applyTempChange,
    previewLoading,
    lastPickedElement,
    resyncMutations,
    resources,
    resetWidget,
    setResources,
    inspectorRef,
    deviceRef,
    newAudiences,
    setNewAudiences,
    cleanLastPickedElement: () => setLastPickedElement({} as NewElementMessage),
    loadedApps,
    isInErrorStateRef,
    currentExtension,
    isEditingCheckoutExtensibility,
    checkoutApps,
    checkoutMountPoints,
    integrationType,
    checkoutSnapshotTimestamp,
  };
}

export interface EditorContextProps {
  resetWidget: boolean;
  cleanLastPickedElement: () => void;
  newAudiences: Array<Audience>;
  setNewAudiences: (a: Array<Audience>) => void;
  revertTempChange: () => void;
  transpiler: TranspilerHook;
  inspectorNav: InspectorNavigationHook;
  devicePreview: DevicePreviewHook;
  deviceNavigation: UseDeviceNavigationHook;
  experienceControls: UseExperienceControls;
  experienceState: ExperienceStateHook;
  applyTempChange: (change: EditorDeclarativeBlock) => void;
  previewLoading: boolean;
  lastPickedElement: NewElementMessage;
  resyncMutations: () => void;
  resources: LoadedEditorResources;
  setResources: (fn: mutateFn<LoadedEditorResources>) => void;
  inspectorRef: React.MutableRefObject<HTMLElement>;
  deviceRef: React.MutableRefObject<HTMLElement>;
  loadedApps: React.MutableRefObject<Array<string>>;
  isInErrorStateRef: React.MutableRefObject<boolean>;
  currentExtension: ExtensionType,
  isEditingCheckoutExtensibility: boolean,
  checkoutApps: CatalogCheckoutApp[]
  checkoutMountPoints: LightweightMountPoint[]
  integrationType:'static'|'spa'
  checkoutSnapshotTimestamp: number
}

export function compoundChangeId(
  selector: string,
  quickPreviewDevice: DeviceType,
) {
  return `${selector}-${quickPreviewDevice?.toString()?.toLowerCase()}`;
}

function useKeepPreviewDevice(
  experienceState: ExperienceStateHook,
  devicePreview: DevicePreviewHook,
) {
  useEffect(() => {
    experienceState.setLastPreviewDevice(devicePreview?.editorState?.device);
  }, [devicePreview?.editorState?.device]);

  useEffect(() => {
    experienceState?.currentExperience?.lastDevicePreview &&
      devicePreview.setPreviewDevice(
        experienceState?.currentExperience?.lastDevicePreview,
      );
  }, []);
}

function usePreviewLoading() {
  const [previewLoading, setPreviewLoading, previewLoadingRef] = useDetachedState(true);
  const timeoutRef = useRef(null);
  useEffect(() => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(
      () => !previewLoading && setPreviewLoading(false),
      1000 * 30,
    );
    return () => clearTimeout(timeoutRef.current);
  }, [previewLoading]);
  return { previewLoading, setPreviewLoading, previewLoadingRef };
}

function expWithDefaultTheme(
  resources: LoadedEditorResources,
  isThemesEnabled: boolean,
) {
  if (!isThemesEnabled) {
    return { ...resources.experience, themeId: undefined };
  }
  const mainThemeId = resources?.themes?.find((t) => t.role === `main`)?.id;
  try {
    let themeId =
      resources.themes?.find((t) => t.id == resources?.experience?.themeId)
        ?.id || 0;
    if (themeId === mainThemeId){
      themeId = 0
    }
    return {
      ...resources.experience,
      themeId,
      mainThemeId,
    };
  } catch (e) {
    return { ...resources.experience, themeId: 0, mainThemeId };
  }
}
